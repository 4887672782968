import {
  DocumentData,
  QueryConstraint,
  QueryDocumentSnapshot,
  collection,
  doc,
  documentId,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  and,
  or,
  collectionGroup,
  QueryFilterConstraint,
} from "firebase/firestore";
import * as lc from "src/app/modules/localstorage/index";
import { useQuery } from "react-query";
import db, { newDB } from "src/db";
import { Filter, FirestoreQueryState, Sorting } from "../models/Models";

export type MapperFn<T> = (
  docs: QueryDocumentSnapshot<T, DocumentData>[]
) => T[] | Promise<T[]>;

export function createDocRef(collection: string, id: string) {
  return doc(newDB, `${collection}/${id}`);
}

export function useFirestoreData<T>(
  props: FirestoreQueryState<T>,
  mapper?: MapperFn<T>
) {
  const { collection, listName } = props;
  const queryResult = useQuery(
    [`firestore-data-${collection}`, props],
    () => getDataFromDB(props, mapper),
    {
      cacheTime: 5 * 60 * 1000, // Cache data for 5 minutes
      staleTime: 1 * 30 * 1000, // Data is fresh for 30 seconds
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  return queryResult;
}

async function getDataFromDB<T>(
  state: FirestoreQueryState<T>,
  mapper?: MapperFn<T>
) {
  const {
    clientID,
    collection: collName,
    sorting = [],
    limit: max,
    filters = [],
    currentPage,
    searchKey,
    collectionGroup: collGroup,
    subcollection,
    subSubcollection,
    listName,
  } = state;
  if (!clientID) {
    throw new Error("Client ID is missing");
  }

  try {
    const id_client = lc.getItemLC(lc.LCName.Client)?.id;
    const clientRef = createDocRef("clients", id_client);
    const baseConstraint = where("client", "==", clientRef);
    const filterConstraints: QueryFilterConstraint[] = [];

    const processFilter = (
      filter: Filter<T>
    ): QueryFilterConstraint | QueryFilterConstraint[] | undefined => {
      if (filter?.type) {
        switch (filter.type) {
          case "text":
          case "number":
          case "option":
            return Array.isArray(filter.value) && filter.value.length > 0
              ? where(filter.field.toString(), "in", filter.value)
              : where(filter.field.toString(), "==", filter.value);

          case "array":
            return where(filter.field.toString(), "==", filter.value);

          case "exception":
            return Array.isArray(filter.value)
              ? where(filter.field.toString(), "not-in", filter.value)
              : where(filter.field.toString(), "!=", filter.value);

          case "date-range":
            return [
              where(filter.field.toString(), ">=", filter.value.startDate),
              where(filter.field.toString(), "<=", filter.value.endDate),
            ];

          case "boolean":
            return where(filter.field.toString(), "==", filter.value);

          case "greater":
            return where(filter.field.toString(), ">", filter.value);

          case "less":
            return where(filter.field.toString(), "<", filter.value);

          case "greater-than":
            return where(filter.field.toString(), ">=", filter.value);

          case "less-than":
            return where(filter.field.toString(), "<=", filter.value);

          case "array-contains-any":
            return where(
              filter.field.toString(),
              "array-contains-any",
              filter.value
            );

          case "null":
            return where(filter.field.toString(), "==", null);

          case "array-in":
            return filter.field.toString() === "id"
              ? where(documentId(), "in", filter.value)
              : where(filter.field.toString(), "in", filter.value);

          case "or":
            const orFilters = filter.filters
              .map(processFilter)
              .flat()
              .filter(Boolean) as QueryFilterConstraint[];
            if (orFilters.length > 0) {
              return or(...orFilters);
            }
            return undefined;

          case "and":
            const andFilters = filter.filters
              .map(processFilter)
              .flat()
              .filter(Boolean) as QueryFilterConstraint[];
            if (andFilters.length > 0) {
              return and(...andFilters);
            }
            return undefined;

          default:
            return undefined;
        }
      }
      return undefined;
    };

    for (const filter of filters) {
      const constraint = processFilter(filter);
      if (constraint) {
        if (Array.isArray(constraint)) {
          filterConstraints.push(...constraint);
        } else {
          filterConstraints.push(constraint);
        }
      }
    }

    const sortingConstraints: QueryConstraint[] = [];
    const sortingFields: string[] = [];
    sorting.forEach((item: any) => {
      if (
        item.field &&
        item.direction &&
        !sortingFields.includes(item.field.toString())
      ) {
        sortingFields.push(item.field.toString());
        sortingConstraints.push(
          orderBy(item.field.toString(), item.direction.toString())
        );
      } else if (item.field && !sortingFields.includes(item.field.toString())) {
        sortingFields.push(item.field.toString());
        sortingConstraints.push(orderBy(item.field.toString()));
      }
    });

    const queryConstraints = and(baseConstraint, ...filterConstraints) as any;

    const countQuery =
      collGroup === true
        ? query(collectionGroup(newDB, collName), queryConstraints)
        : query(collection(newDB, collName), queryConstraints);
    const countSnapshot = await getCountFromServer(countQuery);
    const count = countSnapshot.data().count;

    const dataConstraints: QueryConstraint[] = [
      queryConstraints,
      ...sortingConstraints,
    ];

    if (max) {
      if (currentPage > 1) {
        const tempConstraints: QueryConstraint[] = [
          queryConstraints,
          ...sortingConstraints,
        ];
        tempConstraints.push(limit((currentPage - 1) * max));
        const tempDocs =
          collGroup === true
            ? (
                await getDocs(
                  query(collectionGroup(newDB, collName), ...tempConstraints)
                )
              ).docs
            : (
                await getDocs(
                  query(collection(newDB, collName), ...tempConstraints)
                )
              ).docs;
        if (tempDocs.length > 0) {
          dataConstraints.push(startAfter(tempDocs[tempDocs.length - 1]));
        } else {
          console.error("temp No data found for pagination");
        }
      }
      dataConstraints.push(limit(max));
    }

    const dataQuery =
      collGroup === true
        ? query(collectionGroup(newDB, collName), ...dataConstraints)
        : query(collection(newDB, collName), ...dataConstraints);
    const dataSnapshot = await getDocs(dataQuery);

    let data: T[] = [];

    const getSubcollectionData = async (
      docId: string,
      subcollection: string[],
      subSubcollection: string[] = [] // Provide a default value to avoid undefined
    ) => {
      const subcollectionDataPromises = subcollection.map(
        async (subcollectionName) => {
          const subDocs = await db
            .collection(collName)
            .doc(docId)
            .collection(subcollectionName)
            .get();
          const subDatas = subDocs?.docs?.map((doc: any) => {
            return { id: doc.id, ...doc.data() };
          });
          let finalData = await Promise.all(
            subDatas?.map(async (item: any) => {
              const subSubcollectionData = await Promise.all(
                subSubcollection &&
                  subSubcollection?.map(async (subsub) => {
                    const subSubDocs = await db
                      .collection(collName)
                      .doc(docId)
                      .collection(subcollectionName)
                      .doc(item.id)
                      .collection(subsub)
                      .get();
                    const subsubDatas = subSubDocs?.docs?.map((doc: any) => {
                      return { id: doc.id, ...doc.data() };
                    });
                    return {
                      [subsub]: subsubDatas,
                    };
                  })
              );
              return {
                ...item,
                zsubcollection: Object.assign({}, ...subSubcollectionData),
              };
            })
          );

          return {
            [subcollectionName]: finalData,
          };
        }
      );
      const results = await Promise.all(subcollectionDataPromises);
      return Object.assign({}, ...results);
    };

    console.log(listName);

    if (mapper) {
      const tempData = await mapper(
        dataSnapshot.docs as QueryDocumentSnapshot<T, DocumentData>[]
      );

      await Promise.all(
        tempData.map(async (x: any) => {
          await Promise.all(
            dataSnapshot.docs.map(async (item: any) => {
              if (collGroup === true && item.id === x.id) {
                let data_parent = await db
                  .collection(item?.ref?.parent?.parent?.parent?.id)
                  .doc(item?.ref?.parent?.parent?.id)
                  .get();
                x["parent_id"] = item?.ref?.parent?.parent?.id;
                x["parent_document"] = data_parent.data();
              } else if (
                collGroup === false &&
                item.id === x.id &&
                subcollection &&
                subcollection.length > 0
              ) {
                const subData = await getSubcollectionData(
                  item.id,
                  subcollection as string[],
                  subSubcollection as string[] // Ensure it's defined
                );
                x["zsubcollection"] = subData;
              }
            })
          );
        })
      );

      data = [...tempData];
    } else {
      const getFromDb = async (collection: any, doc: any) => {
        const res = await db.collection(collection).doc(doc).get();
        return res.data();
      };

      data = await Promise.all(
        dataSnapshot?.docs?.map(async (doc: any) => {
          if (collGroup === true) {
            const data_parent = await getFromDb(
              doc?.ref?.parent?.parent?.parent?.id,
              doc?.ref?.parent?.parent?.id
            );
            return {
              id: doc.id,
              parent_id: doc?.ref?.parent?.parent?.id,
              parent_document: data_parent,
              ...doc.data(),
            };
          } else {
            return {
              id: doc.id,
              ...doc.data(),
            };
          }
        })
      );
    }

    if (listName === "value-pack") {
      // Group by itemList[0].itemSKU
      const groupedData = data.reduce((acc: any, item: any) => {
        const sku = item?.itemList?.[0]?.itemSKU; // Access itemSKU from itemList[0]
        if (sku) {
          if (!acc[sku]) {
            acc[sku] = []; // Initialize an array if the SKU does not exist in the accumulator
          }
          acc[sku].push(item); // Push the current item to the appropriate SKU group
        }
        return acc;
      }, {});

      // Use Promise.all to resolve the promises returned by the async map
      data = (await Promise.all(
        Object.keys(groupedData).map(async (sku) => {
          const orders = groupedData[sku];

          const getProduct = await db
            .collection("products")
            .where("sku", "==", sku)
            .get();
          const product: any = {
            id: getProduct.docs[0].id,
            ...getProduct.docs[0].data(),
          };

          const whRef = createDocRef("warehouses", "Labstore_1000013");
          const getStorages = await db
            .collectionGroup("storage")
            .where("productRef", "==", getProduct.docs[0].ref)
            .where("clientRef", "==", clientRef)
            .where("warehouseRef", "==", whRef)
            .where("onHandQty", ">", 0)
            .orderBy("onHandQty", "desc")
            .get();

          const storage = getStorages.docs.map((doc: any) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const locators = await Promise.all(
            storage.map(async (item: any) => {
              const locatorRef = item.locatorRef;
              const onHandQty = item.onHandQty;
              if (!locatorRef) return null;

              const locatorDoc = await locatorRef.get();
              if (!locatorDoc.exists) return null;

              const locatorData = { id: locatorDoc.id, ...locatorDoc.data() };
              return { code: locatorData?.code, stock: onHandQty };
            })
          );

          const groupedLocators = locators
            .filter(Boolean)
            .reduce((acc: Record<string, number>, item: any) => {
              if (item.code) {
                acc[item.code] = (acc[item.code] || 0) + item.stock;
              }
              return acc;
            }, {});

          const uniqueLocators = Object.entries(groupedLocators).map(
            ([code, stock]) => ({
              code,
              stock,
            })
          );

          // Find the earliest marketplaceOrderCreatedAt in the orders
          const earliestOrderTime = orders
            .map((item: any) => item?.marketplaceOrderCreatedAt?.seconds) // Get seconds from Firestore timestamp
            .filter((timestamp: any) => timestamp !== undefined) // Filter out undefined values
            .reduce(
              (earliest: any, timestamp: any) =>
                timestamp < earliest ? timestamp : earliest,
              Infinity
            );

          // Find the earliest shipByDate in the orders
          const earliestShipByDate = orders.sort(
            (a: any, b: any) => a?.shipByDate?.seconds - b?.shipByDate?.seconds
          )[0]?.shipByDate;

          return {
            productSKU: sku,
            productName: product?.name,
            storages: uniqueLocators,
            totalStorages: uniqueLocators?.length,
            allOrders: orders.map((item: any) => item?.ordersn),
            totalOrders: orders?.length,
            orderTime:
              earliestOrderTime === Infinity
                ? null
                : new Date(earliestOrderTime * 1000),
            shipByDate: earliestShipByDate,
          };
        })
      )) as any;
    }

    return { allCount: count, items: data };
  } catch (error) {
    console.error("Error fetching data from Firestore:", error);
    throw error;
  }
}

export default useFirestoreData;
