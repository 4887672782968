import { useTranslation } from "react-i18next";
import { PageLink, PageTitle } from "../../core/PageData";
import ListComponent from "src/app/components/list/NewList";
import { CIQsColumnType } from "../ciq-list/table/columns/_columns";
import { CIQChannelCell } from "../ciq-list/table/columns/CIQChannelCell";
import { CIQInfoCell } from "./component/InfoCell";
import { CIQCAccountCell } from "../ciq-list/table/columns/CIQAccountCell";
import { CIQPhoneNumberCell } from "../ciq-list/table/columns/CIQPhoneNumberCell";
import CellDate from "src/app/components/table/columns/CellDate";
import { CIQActionsCell } from "../ciq-list/table/columns/CIQActionsCell";
import clsx from "clsx";
import * as lc from "src/app/modules/localstorage/index";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as chat from "../../../../app/modules/chat/redux/ChatSlice";
import * as Chat from "../../../../actions/chat";
import * as CIQ from "../../../../app/modules/ciq/redux/CIQSlice";
import { useMutation } from "react-query";
import { createNotificationAnotherUser } from "src/db/serviceUser";
import { ChatType } from "src/app/modules/chat/feature";
import { useEffect, useState } from "react";
import db, { Timestamp } from "src/db";
import { handleCIQ } from "./component/ActionCell";
import * as LC from "../../../../app/modules/localstorage/index";

type Columns<T> = {
  title: string;
  field: keyof T;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};
type Action<T> = {
  headerTitle: string;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};

const CIQNewList = () => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [notifTrigger, setNotifTrigger] = useState(0);
  const [tokopediaDisplayTimestamp, setTokopediaDisplayTimestamp] =
    useState<any>(null);
  const [shopeeDisplayTimestamp, setShopeeDisplayTimestamp] =
    useState<any>(null);
  const [lazadaDisplayTimestamp, setLazadaDisplayTimestamp] =
    useState<any>(null);
  const [whatsappDisplayTimestamp, setWhatsappDisplayTimestamp] =
    useState<any>(null);

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("CIQ.title.CIQ"),
      path: "/ciq/list",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  const defaultColumns: Columns<CIQsColumnType>[] = [
    {
      title: "Name",
      field: "profileName",
      value: (props: any) => {
        return <CIQInfoCell CIQ={props.data[props.row.index]} />;
      },
    },
    {
      title: "Channel",
      field: "accountData.type" as any,
      value: (props: any) => {
        return (
          <CIQChannelCell channel={props.row?.original?.accountData?.type} />
        );
      },
    },
    {
      title: "Account Name",
      field: "accountData.name" as any,
      value: (props: any) => {
        return (
          <CIQCAccountCell
            accountName={props.row?.original?.accountData?.name}
          />
        );
      },
    },
    {
      title: "Phone Number",
      field: "phoneNumber",
      value: (props: any) => {
        return (
          <CIQPhoneNumberCell
            phoneNumber={
              props.data[props.row.index]?.parent_document?.phoneNumber
            }
          />
        );
      },
    },
    {
      title: "Received",
      field: "lastCustomerInteractionAt",
      value: (props: any) => {
        return (
          <CellDate
            value={
              props.data[props.row.index]?.lastCustomerInteractionAt ||
              props.data[props.row.index]?.updatedAt
            }
          />
        );
      },
    },
  ];

  const defaultAction: Action<CIQsColumnType> = {
    headerTitle: "Action",
    value: (props: any) => {
      return (
        <div className="p-0 m-2">
          <CIQActionsCell
            id={props.row.original.id}
            collaborationId={props.row.original.parent_id}
            indexId={props.row.index}
            lastInteractionDate={props.row.original.lastCustomerInteractionAt}
            accountType={props.row.original.accountData.type}
            rooms={props.row.original}
          />
        </div>
      );
    },
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      window.document.getElementById("button-refetch")?.click();
    }

    const array = LC.getItemLC("Notify");
    if (array && array.length > 1) {
      const sortedArray = array?.reverse();
      setNotifications(sortedArray);
    }

    const fetchClientData = async (clientId: string) => {
      try {
        const fetchClient = await db.collection("clients").doc(clientId).get();
        if (fetchClient?.exists) {
          return { id: fetchClient.id, ...fetchClient.data() };
        }
        return null;
      } catch (error) {
        console.error("Error fetching client data:", error);
        return null;
      }
    };

    const getDisplayTimestamp = async () => {
      const clientId = lc.getItemLC(lc.LCName.Client)?.id;
      const currentTimestamp = Timestamp.now();
      if (clientId) {
        const clientData: any = await fetchClientData(clientId);

        if (clientData?.ciqDisplay) {
          const { Tokopedia, Shopee, Lazada, Whatsapp } = clientData.ciqDisplay;

          // Pastikan konversi jam ke milidetik
          const tokopediaConfigDisplayHour = Tokopedia || 0;
          const shopeeConfigDisplayHour = Shopee || 0;
          const lazadaConfigDisplayHour = Lazada || 0;
          const whatsappConfigDisplayHour = Whatsapp || 0;

          setTokopediaDisplayTimestamp(
            tokopediaConfigDisplayHour !== 0
              ? Timestamp.fromMillis(
                  currentTimestamp.toMillis() -
                    tokopediaConfigDisplayHour * 60 * 60 * 1000
                )
              : null
          );
          setShopeeDisplayTimestamp(
            shopeeConfigDisplayHour !== 0
              ? Timestamp.fromMillis(
                  currentTimestamp.toMillis() -
                    shopeeConfigDisplayHour * 60 * 60 * 1000
                )
              : null
          );
          setLazadaDisplayTimestamp(
            lazadaConfigDisplayHour !== 0
              ? Timestamp.fromMillis(
                  currentTimestamp.toMillis() -
                    lazadaConfigDisplayHour * 60 * 60 * 1000
                )
              : null
          );
          setWhatsappDisplayTimestamp(
            whatsappConfigDisplayHour !== 0
              ? Timestamp.fromMillis(
                  currentTimestamp.toMillis() -
                    whatsappConfigDisplayHour * 60 * 60 * 1000
                )
              : null
          );
          window.document.getElementById("button-refetch")?.click();
        } else {
          console.warn("ciqDisplay data is not available in clientData.");
        }
      }
    };

    getDisplayTimestamp();

    function storageEventHandler(event: any) {
      const array = LC.getItemLC("Notify");
      if (array) {
        const sortedArray = array.reverse();
        setNotifications((prevNotifications) => {
          if (
            JSON.stringify(prevNotifications) !== JSON.stringify(sortedArray)
          ) {
            setNotifTrigger((prev) => prev + 1);
            return sortedArray;
          }
          return prevNotifications;
        });
      }
    }

    window.addEventListener("storageNotif", storageEventHandler);

    return () => {
      isMounted = false;
      window.removeEventListener("storageNotif", storageEventHandler);
      getDisplayTimestamp();
    };
  }, [notifTrigger]);

  useEffect(() => {
    window.document.getElementById("button-refetch")?.click();
  }, [
    tokopediaDisplayTimestamp,
    shopeeDisplayTimestamp,
    whatsappDisplayTimestamp,
    lazadaDisplayTimestamp,
  ]);

  const whatsappFilter = {
    type: "and",
    filters: [
      whatsappDisplayTimestamp !== null && {
        type: "greater-than",
        label: "lastCustomerInteractionAt",
        field: "lastCustomerInteractionAt",
        value: whatsappDisplayTimestamp,
      },
      {
        type: "text",
        label: "Channel",
        field: "accountData.type",
        value: "whatsapp",
      },
      {
        type: "or",
        filters: [
          { type: "text", label: "Status", field: "status", value: "queue" },
          {
            type: "and",
            filters: [
              {
                type: "text",
                label: "Status",
                field: "status",
                value: "handled",
              },
              {
                type: "text",
                label: "ToUserOnline",
                field: "toUserOnline",
                value: [],
              },
            ],
          },
        ],
      },
    ],
  };

  const marketplaceFilter = {
    type: "and",
    filters: [
      {
        type: "or",
        filters: [
          {
            type: "and",
            filters: [
              {
                type: "text",
                label: "Channel",
                field: "accountData.type",
                value: "shopee",
              },
              shopeeDisplayTimestamp !== null && {
                type: "greater-than",
                label: "lastCustomerInteractionAt",
                field: "lastCustomerInteractionAt",
                value: shopeeDisplayTimestamp,
              },
            ],
          },
          {
            type: "and",
            filters: [
              {
                type: "text",
                label: "Channel",
                field: "accountData.type",
                value: "tokopedia",
              },
              tokopediaDisplayTimestamp !== null && {
                type: "greater-than",
                label: "lastCustomerInteractionAt",
                field: "lastCustomerInteractionAt",
                value: tokopediaDisplayTimestamp,
              },
            ],
          },
          {
            type: "and",
            filters: [
              {
                type: "text",
                label: "Channel",
                field: "accountData.type",
                value: "lazada",
              },
              lazadaDisplayTimestamp !== null && {
                type: "greater-than",
                label: "lastCustomerInteractionAt",
                field: "lastCustomerInteractionAt",
                value: lazadaDisplayTimestamp,
              },
            ],
          },
        ],
      },
      {
        type: "or",
        filters: [
          { type: "text", label: "Status", field: "status", value: "queue" },
          {
            type: "and",
            filters: [
              {
                type: "text",
                label: "Status",
                field: "status",
                value: "handled",
              },
              {
                type: "text",
                label: "ToUserOnline",
                field: "toUserOnline",
                value: [],
              },
            ],
          },
        ],
      },
    ],
  };

  const combinedFilter = {
    type: "or",
    filters: [whatsappFilter, marketplaceFilter],
  };

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>{t("CIQ.title.CIQ")}</PageTitle>
      <ListComponent<CIQsColumnType>
        collection="rooms"
        collectionGroup={true}
        columns={defaultColumns}
        selection={{
          rowSelection: true,
          headerSelection: true,
          disableRowSelection(props: any) {
            const twentyFourHoursAgo = new Date(
              Date.now() - 24 * 60 * 60 * 1000
            );
            const lastInteraction = props?.lastCustomerInteractionAt?.toDate();
            if (
              props?.accountData?.type === "whatsapp" &&
              lastInteraction < twentyFourHoursAgo
            ) {
              return false;
            } else {
              return true;
            }
          },
        }}
        defaultFilters={[combinedFilter] as any}
        defaultSorting={[
          {
            field: "lastCustomerInteractionAt",
            direction: "asc",
            label: "lastCustomerInteractionAt",
          },
        ]}
        useFilter={[]}
        searching={{
          field: "profileName",
          useFirestore: true,
          value: "",
          caseSensitive: false,
        }}
        extra={[
          {
            label: "handle_selection",
            value: (props: any) => {
              const ExtraValue = () => {
                let selected = props?.selectedItems;
                let ciqList = props?.data?.items;
                const UpdateSelectedItems = useMutation(async () => {
                  const selectedCIQ = ciqList.filter((ciq: any) =>
                    selected.includes(ciq?.id)
                  );
                  for (const ciq of selectedCIQ) {
                    const roomId = ciq?.id;
                    const collaborationId = ciq?.parent_id;
                    const handleCIQResult = await handleCIQ(
                      collaborationId,
                      roomId
                    );
                    const collab = await Chat.fetchCollaborationByID(
                      collaborationId
                    );

                    if (!collab) {
                      return;
                    }

                    if (handleCIQResult) {
                      dispatch(chat.setSelectedChat(collab.id));
                      dispatch(chat.setSelectedRoom(roomId));

                      if (
                        collab.company &&
                        collab.company?.id &&
                        collab.customer
                      ) {
                        createNotificationAnotherUser(collab);
                      }
                    }
                  }
                  selectedCIQ?.forEach((ciq: any) => {
                    dispatch(CIQ.deleteItemListCIQ(ciq.id.toString()));
                  });
                  window.document.getElementById("button-refetch")?.click();
                });
                return (
                  <>
                    {selected.length > 0 && (
                      <Link
                        to={"/handled-customer"}
                        data-testid="btn-add-to-target"
                        type="button"
                        className={clsx(
                          "btn btn-primary",
                          isMobileView ? "w-100 my-5" : ""
                        )}
                        onClick={async () => {
                          await UpdateSelectedItems.mutateAsync();
                          dispatch(chat.setCurrentChatState(ChatType.mychat));
                        }}
                      >
                        {t("CIQ.Button.HandleSelected")}
                      </Link>
                    )}
                  </>
                );
              };
              return ExtraValue();
            },
          },
        ]}
        action={defaultAction}
      />
    </>
  );
};

export default CIQNewList;
