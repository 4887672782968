import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// import "firebase/compat/messaging";
import "firebase/compat/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// import { getMessaging } from "firebase/messaging/sw";
import { getFunctions } from 'firebase/functions';
import * as Log from "src/util/SDayslogger"

// Initialize Firebase
let firebases: firebase.app.App;
// firebases = firebase.initializeApp(staggingConfig);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_CONFIG_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENTID,
}
export { firebaseConfig }

firebases = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(); // getFirestore()
const realtimedb = firebase.database()
const newDB = getFirestore(firebases!);
const functions = getFunctions(firebases!);
// const messaging = getMessaging(firebases!);
const storage = firebase.storage();

Log.debug(`firebase emulator : ${process.env.REACT_APP_USE_FIREBASE_EMU}`);
// if (
//   process.env.REACT_APP_USE_FIREBASE_EMU &&
//   process.env.REACT_APP_USE_FIREBASE_EMU === "true"
// ) {
//   // db.useEmulator("192.168.20.20", 8009);
//   const host = "127.0.0.1"
//   db.useEmulator(host, 8080)
//   connectFirestoreEmulator(newDB, host, 8080)
//   realtimedb.useEmulator(host,9000)
// }


const { Timestamp } = firebase.firestore;
const createFirebaseRef = (collection: any, id: any) =>
  firebase.database().ref(`/${collection}/${id}`);

export {
  firebases,
  db,
  storage,
  Timestamp,
  createFirebaseRef,
  // messaging,
  newDB,
  functions,
  realtimedb
};
export * from "./connection";
export * from "./serviceCompany";
export * from "./serviceCustomer";
export * from "./serviceFiles";
export * from "./serviceUser";
export default db;