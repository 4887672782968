import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { scanBarcode } from "src/api/server/prepacks";
import ShippingMarkModal from "./ShippingMarkModal";
import axios from "axios";
import db from "src/db";
import * as lc from "../../../../../app/modules/localstorage/index";
import { KTSVG } from "src/resources/helpers";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const LoadingSpinner = () => (
  <div className="spinner-border spinner-border-sm text-primary" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
);

/**
 * ReprintModalProps Interface
 */
type ReprintModalProps = {
  onClose: () => void;
  onSubmit?: (data: { locator: string; sku: string }) => void;
};

/**
 * ReprintModal is a modal component that allows the user to scan a locator and input a SKU.
 * It will validate the locator and SKU and submit the form if both are valid.
 * It also handles errors and displays them to the user.
 *
 * @param {function} onClose - A function to call when the modal is closed.
 * @param {function} onSubmit - A function to call when the form is submitted.
 * @returns {JSX.Element} - The modal component.
 */
const ReprintModal: React.FC<ReprintModalProps> = ({ onClose, onSubmit }) => {
  const { t } = useTranslation();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<any[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<any[]>([]);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setSearchLoading(true);
        setError(null);

        // Mendapatkan timestamp pukul 00.00 hari ini
        const todayStart = new Date();
        todayStart.setHours(0, 0, 0, 0);
        const todayTimestamp = Timestamp.fromDate(todayStart);

        // Query ke Firestore untuk mendapatkan orders
        const querySnapshot = await db
          .collection("orders")
          .where("printedLabel", ">", todayTimestamp)
          .orderBy("printedLabel", "desc")
          .get();

        const orders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Ambil productSKU dan cari productName
        const fetchedOrders = await Promise.all(
          orders.map(async (order: any) => {
            const productSKU = order?.itemList?.[0]?.itemSKU || "";
            let productName = "Unknown Product";

            if (productSKU) {
              try {
                const productDoc = await db
                  .collection("products")
                  .where("sku", "==", productSKU)
                  .limit(1)
                  .get();

                if (!productDoc.empty) {
                  productName =
                    productDoc.docs[0].data().name || "Unknown Product";
                }
              } catch (productError) {
                console.error("Error fetching product:", productError);
              }
            }

            return {
              ...order,
              productSKU,
              productName,
            };
          })
        );

        setOrders(fetchedOrders);
        setFilteredOrders(fetchedOrders);
      } catch (err) {
        setError("Error fetching orders.");
        console.error("Error fetching orders:", err);
      } finally {
        setSearchLoading(false);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredOrders(orders);
    } else {
      const lowercasedSearch = searchTerm.toLowerCase();
      setFilteredOrders(
        orders.filter(
          (order) =>
            order.ordersn?.toLowerCase().includes(lowercasedSearch) ||
            order.productSKU?.toLowerCase().includes(lowercasedSearch) ||
            order.productName?.toLowerCase().includes(lowercasedSearch) ||
            order.shippingTrackingNumber
              ?.toLowerCase()
              .includes(lowercasedSearch)
        )
      );
    }
  }, [searchTerm, orders]);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const ForwardInfoComponent = ({ data }: { data: any }) => {
    const key = `${data.id}-forward-error-tooltip`;

    if (data.isForwardSuccess == null) {
      return (
        <div className="m-0 p-0 d-flex justify-content-start fw-bolder">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
              <Tooltip {...props} id={key} className="fs-5">
                {t("OrderList.ForwardError.OrderNotForwarded")}
              </Tooltip>
            )}
          >
            <i className="bi bi-info-circle-fill text-warning fs-1"></i>
          </OverlayTrigger>
        </div>
      );
    }

    // If forwardError is false it means that the order has been forwarded successfully
    if (!data.forwardError && data.isForwardSuccess) {
      return (
        <div className="m-0 p-0 d-flex justify-content-start fw-bolder">
          <i className="bi bi-check-circle-fill text-success fs-1"></i>
        </div>
      );
    }

    // If isForwardError is true it means that the order has not been forwarded
    return (
      <div className="m-0 p-0 d-flex justify-content-start fw-bolder">
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) => (
            <Tooltip {...props} id={key} className="fs-5">
              {data.forwardError ?? t("OrderList.ForwardError.DefaultError")}
            </Tooltip>
          )}
        >
          <i className="bi bi-exclamation-circle-fill text-danger fs-1"></i>
        </OverlayTrigger>
      </div>
    );
  };

  const exportToCSV = () => {
    const csvRows = [];
    const headers = [
      "ordersn",
      "shippingTrackingNumber",
      "productSKU",
      "productName",
      "printedLabel",
    ];
    csvRows.push(headers.join(","));

    filteredOrders.forEach((order) => {
      const row = [
        order.ordersn,
        order.shippingTrackingNumber,
        order.productSKU,
        order.productName,
        moment(order.printedLabel.toDate()).format("YYYY-MM-DD HH:mm:ss"),
      ];
      csvRows.push(row.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `printed-vp-orders-${moment().format("DD-MM-YYYY")}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="modal-backdrop show"></div>
      <div className="modal show d-block">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header py-3">
              <h5 className="modal-title m-0 p-0">
                {t("Value.Pack.Scan.Reprint")}
              </h5>
              <button
                className="btn btn-sm btn-light m-0 p-0"
                onClick={onClose}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </button>
            </div>

            {/* Modal Body */}
            <div className="modal-body py-0 my-2">
              {/* Search Input */}
              <div className="mb-2 text-start">
                <div className="input-group py-0">
                  {/* Icon Search */}
                  <span className="input-group-text h-100 m-0">
                    <KTSVG
                      path="/media/icons/duotune/general/gen021.svg"
                      className="svg-icon"
                    />
                  </span>

                  {/* Input */}
                  <input
                    id="search-input"
                    ref={searchInputRef}
                    type="text"
                    className={`form-control ${
                      error ? "is-invalid" : ""
                    } my-0 h-100`}
                    value={searchTerm}
                    onChange={handleSearchInput}
                    placeholder={t("Value.Pack.Search.Placeholder")}
                  />

                  {/* Loading Icon */}
                  {searchLoading && (
                    <span className="input-group-text m-0 h-100">
                      <LoadingSpinner />
                    </span>
                  )}

                  {error && <div className="invalid-feedback">{error}</div>}
                </div>
              </div>

              <p className="m-0 py-0 text-sm">
                {orders?.length} {t("Value.Pack.Info.Printed")}{" "}
                {moment().format("DD MMMM YYYY")}
                {filteredOrders?.length > 0 && searchTerm !== "" && (
                  <span>
                    {" ( "} Search for {searchTerm} : {filteredOrders.length}{" "}
                    {t("Value.Pack.Breadcrumb")}
                    {" )"}
                  </span>
                )}
              </p>

              {/* Orders List */}
              <div
                className="table-responsive mt-2 border border-gray border-2 rounded"
                style={{
                  maxHeight: "320px",
                  overflowY: "auto",
                }}
              >
                <table className="table">
                  <thead className="sticky-top bg-secondary text-dark">
                    <tr className="align-middle">
                      <th className="w-auto text-wrap">
                        {t("OrderList.OrderNumber")}
                      </th>
                      <th className="w-auto text-wrap">
                        {t("HandledCustomer.Modal.Invoice.TrackingNumber")}
                      </th>
                      <th className="w-auto text-wrap">
                        {t("Value.Pack.ProductName")}
                      </th>
                      <th className="w-auto text-wrap">
                        {t("Value.Pack.ProductSKU")}
                      </th>
                      <th className="w-auto text-wrap">
                        {t("Value.Pack.Printed")}
                      </th>
                      <th className="w-auto text-wrap">
                        {t("OrderList.ForwardError")}
                      </th>{" "}
                      <th className="w-auto text-wrap">
                        {t("Common.Actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOrders.length > 0 ? (
                      filteredOrders.map((order) => (
                        <tr
                          key={order.id}
                          className="align-middle border-bottom border-gray border-2 py-1 my-1 text-sm"
                        >
                          <td className="w-auto text-wrap">{order.ordersn}</td>
                          <td className="w-auto text-wrap">
                            {order.shippingTrackingNumber}
                          </td>
                          <td className="w-auto text-wrap">
                            {order.productName}
                          </td>
                          <td className="w-auto text-wrap">
                            {order.productSKU}
                          </td>
                          <td className="w-auto text-wrap">
                            {moment(order.printedLabel.toDate()).format(
                              "HH:mm:ss"
                            )}
                          </td>
                          <td className="w-auto text-wrap text-center">
                            <ForwardInfoComponent data={order} />
                          </td>
                          <td className="w-auto text-wrap">
                            <button
                              className="w-100 btn btn-primary btn-sm py-2 my-1"
                              onClick={() => {
                                if (order.shippingLabelFile?.url) {
                                  const link = document.createElement("a");
                                  link.href = order.shippingLabelFile.url;
                                  link.download = `shipping-label-${order.ordersn}.pdf`; // Nama file saat didownload
                                  link.target = "_blank"; // Buka di tab baru jika perlu
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                                } else {
                                  alert("File tidak tersedia.");
                                }
                              }}
                            >
                              {t("Value.Pack.Print")}
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          {t("Order.List.Empty")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <p className="m-0 mt-1 p-0 text-warning text-sm">
                <i className="bi bi-exclamation-triangle text-warning me-2"></i>
                {t("Value.Pack.Scan.Reprint.Info")}
              </p>
            </div>

            {/* Modal Footer */}
            <div className="modal-footer py-3 my-0">
              <button onClick={exportToCSV} className="btn btn-primary my-0">
                {t("ExportOrder.Title")}
              </button>
              <button onClick={onClose} className="btn btn-secondary my-0">
                {t("Common.Button.Cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReprintModal;
